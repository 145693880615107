/* eslint-disable */

window.tiptapp = window.tiptapp || {};
tiptapp.views = tiptapp.views || {};
tiptapp.format = tiptapp.format || {};

// utility functions
tiptapp.format.phone = function(phone) {
  if (gMarket.countryCode !== 'GB') {
    return phone.replace(/[\s-\.]/g, '').replace('^00', '+');
  }

  let matches = new RegExp(gMarket.phoneNumberRegexp, 'g').exec(phone);
  const diallingCodeMatchesRE =
    matches &&
    matches
      .slice(1, 4)
      .join('')
      .replace('+', '\\+');
  return phone
    .replace(/^0/, '')
    .replace(new RegExp(`^${diallingCodeMatchesRE}`), gMarket.diallingCode)
    .replace(/[ -]/g, '');
};

tiptapp.getAd = function() {
  return tiptapp.store.ad.get();
};

tiptapp.getUser = function() {
  return tiptapp.store.user.get();
};

tiptapp.saveUser = function(user) {
  tiptapp.store.user.set(user);
};

/**
 * Fetches address data ready to send to the server.
 *
 * @param {string} direction The address to fetch, either `to` or `from`.
 * @returns {object} The specified address data, ready to send to the server.
 */
tiptapp.getAddress = function(direction) {
  let raw = tiptapp.store.address.get(`${direction}Address`);
  try {
    if (!raw) {
      // raw = JSON.parse($(`input[name='${direction}Address]`).attr('data-tiptappAddress'));
      return null;
    }

    // Ensure that the expected fields exists.
    const result = {
      loc: raw.loc,
      street: raw.street,
      streetNo: raw.streetNo,
      zip: raw.zip || '00000',
      area: raw.area,
      country: raw.country,
      name: raw.name,
    };
    if (Object.keys(result).length !== Object.values(result).filter(ith => !!ith).length) {
      throw new Error();
    }

    return result;
  } catch (err) {
    console.error(
      `Could not find a correctly stored "${direction}-address", neither as a tag attribute named "data-tiptappAddress" nor in web storage under key "${direction}Address"! Data: ${JSON.stringify(
        raw
      )}`
    );
    tiptapp.validation.validateAddress(`${direction}-address`);
    throw err;
  }
};

/**
 * Extract and return address information for the given direction (from or to.)
 *
 * @param {string} direction Either `to` or `from`.
 * @returns {object} The address information for the given direction, ready to be sent to the server.
 */
tiptapp.getAddressInfo = function(direction) {
  var result = {
    doorCode: $(`input[name='${direction}DoorCode']`).val(),
    floor: $(`input[name='${direction}Floor']`).val(),
    ownerAssistanceAvailable: $(`input[type=checkbox][name='${direction}OwnerAssistanceAvailableCarry']`).prop('checked') ? 'carry' : 'none',
    lift: $(`input[type=checkbox][name='${direction}Elevator']`).prop('checked'),
    // otherInfo: $(`input[name='${direction}OtherInfo']`).val(),
  };

  const phone = $(`input[name='${direction}Phone']`).val();
  if (phone) {
    result.contactInfo = {
      phone: tiptapp.format.phone(phone),
    };
  }

  // Delete all falsy values.
  Object.keys(result).forEach(function onEach(ith) {
    if (!result[ith]) {
      delete result[ith];
    }
  });

  const InTheHome = 8;
  const InStore = 9;
  const UserWillMeetAndShow = 6;
  result.pos = InTheHome;
  if (direction === 'from' && (gVariant === 'tapaway' || gVariant === 'fb')) {
    result.pos = $('#from-position').val();
  } else if (direction === 'from' && (gVariant === 'store' || gVariant === 'coop' || gVariant === 'klint' || gVariant === 'budi')) {
    result.pos = InStore;
  }

  if (direction === 'from' && (gVariant === 'store' || gVariant === 'coop' || gVariant === 'klint')) {
    let rawAddress;

    try {
      rawAddress = JSON.parse($(`input[name='fromAddress']`).attr('data-tiptappAddress'));
    } catch (error) {
      rawAddress = tiptapp.store.address.get(`fromAddress`);
    }
    if (rawAddress && rawAddress.displayName) {
      result.otherInfo = rawAddress.displayName;
      result.storeName = rawAddress.displayName;
    }
  }

  if (direction === 'from' && window.gVariant === 'budi') {
    result.otherInfo = i18next.t('address_other_info');
  }

  return { ...result, ...tiptapp.getAddress(direction) };
};

/**
 * Extract and return the ad information inserted by the user.
 *
 * @returns {object} The form data, formatted as an ad ready to submit to the API.
 */
tiptapp.getAdInfo = function() {
  let ad = {
    adType: gVariant === 'coop' ? 6 : gVariant === 'tapaway' || gVariant === 'fb' ? 1 : 4,
    title: $('input[name=adTitle]').val(),
    desc: window.gVariant === 'budi' ? window.gExternalAd.description : gAdDescription,
    pay: $('input[name=adPrice]').val(),
  };

  const payRecommendation = $('#recommended-price').text() || null;
  if (payRecommendation) {
    ad.recommendedPay = parseInt(payRecommendation);
  }

  if (tiptapp.shoppingList) {
    ad.shoppingList = tiptapp.shoppingList;
  }

  const sizesMap = { S: 'small', M: 'medium', L: 'large', XL: 'xlarge' };
  const sizeFormVal = $('input[name=priceGroup]:checked').val();
  if (sizeFormVal && sizesMap[sizeFormVal]) {
    ad.size = sizesMap[sizeFormVal];
  }

  if ($('#submit-campaign-code').hasClass('confirmed')) {
    ad.campaignCode = $('#campaign-code').val();
  }
  if (gVariant === 'tapaway' || gVariant === 'fb') {
    ad = { ...ad, ...tiptapp.getAddressInfo('from') };
  } else {
    ad.to = tiptapp.getAddressInfo('to');
    ad.from = tiptapp.getAddressInfo('from');
    ad.ownSlots = tiptapp.form.timeSlots.getSelected({ twoHourSlots: ['coop'].includes(gVariant) });
  }

  return ad;
};

// submit ad create form to /blocket/ad-create; on success shows the login screen, otherwise error/s
$('#ad-create-form').submit(function(event) {
  // stop form from submit normally
  event.preventDefault();

  $(`#ad-create-form input`).attr('touched', 'true');

  const hasErrors = tiptapp.validation.validateOnPublish();
  if (hasErrors) return;

  tiptapp.store.ad.set(tiptapp.getAdInfo());

  // // get form elements and other values
  const $form = $(this),
    phone = $('#toPhone').val() || $('#fromPhone').val(),
    urlRequestCode = $form.attr('action');

  //set phone number for login screen
  $('#verify-phone-no').text(phone);

  tiptapp.analytics.trackEvent('web_create_ad_form_continue');

  if (tiptapp.insurance && tiptapp.insurance.enabled) {
    tiptapp.store.phone.set(phone);
    tiptapp.setScreen('insurance-state');
  } else if (gVariant === 'tapaway' && tiptapp.wasteCategoriesData && tiptapp.wasteCategoriesData.length) {
    tiptapp.store.phone.set(phone);
    tiptapp.setScreen('waste-categories-state');
  } else {
    tiptapp.getLoginCode(urlRequestCode, phone);
  }
});

// submit insurance form
$('#insurance-form').submit(event => {
  event.preventDefault();

  $('#insurance-form input').attr('touched', 'true');

  const hasErrors = tiptapp.validation.validateInsurance();
  if (hasErrors) {
    return;
  }

  const email = tiptapp.blocket.getInsuranceEmail();
  tiptapp.store.email.set(email);

  const insurance = tiptapp.blocket.getSelectedInsurance();
  tiptapp.store.ad.updateInsurance(insurance.premiumCents, insurance.insuredAmountCents);

  const sellingPoints = isObject(tiptapp.insurance) ? tiptapp.insurance.sellingPoints || {} : {};

  tiptapp.analytics.trackEvent('web_insurance_form_continue', {
    premiumCents: insurance.premiumCents,
    sellingPoints,
  });

  const phone = tiptapp.store.phone.get();
  $('#verify-phone-no').text(phone);

  const url = $('#insurance-form').attr('action');
  tiptapp.getLoginCode(url, phone);
});

// re-send login code to phone
$('#resend-to').click(function() {
  // Set new state and phone. Show login code screen
  tiptapp.setScreen('resend-code-state');
});

// edit phone and re-send login code
$('#change-phone').click(function() {
  // Set new state and phone. Show login code screen
  tiptapp.setScreen('resend-code-state');
});

// re-send login to a new phone
$('#resend-form').submit(function(event) {
  // stop form from submit normally
  event.preventDefault();

  const $form = $(this);
  const phone = $('#resend-phone').val();
  const urlRequestCode = $form.attr('action');

  tiptapp.store.ad.updatePhoneNumber('to', phone);
  tiptapp.setScreen('login-state');

  tiptapp.getLoginCode(urlRequestCode, phone, tiptapp.grecaptchaResendId);
});

// login form submission
$('#login-form').submit(onLoginFormSubmit);

async function onLoginFormSubmit(event) {
  tiptapp.setScreen('loading-state');

  // stop form from submit normally
  event.preventDefault();

  const code = $("input[name='loginCode']").val();
  const ad = tiptapp.store.ad.get();
  let phone = ad.to && ad.to.contactInfo ? ad.to.contactInfo.phone : ad.contactInfo ? ad.contactInfo.phone : null;
  const urlLogin = $(this).attr('action');

  ad.reference = String(gExternalAd.id);

  try {
    await createAd(ad, urlLogin, phone, code);
  } catch (error) {
    tiptapp.blocket.toggleErrorModal(true, error.message);
    if (error.type === 'login') {
      tiptapp.setScreen('resend-code-state'); // TODO: if login error
    } else {
      tiptapp.setScreen('begin-state'); // TODO: if campaign code error
    }
  }
}

async function createAd(ad, urlLogin, phone, code) {
  const { user, authorization } = await login(urlLogin, phone, code);

  tiptapp.analytics.trackEvent('web_verify_phone');
  tiptapp.analytics.setUser(user._id);

  const images = getImages();
  const email = tiptapp.store.email.get();
  const { dynamicLink, item } = await publishAd(ad, images, user, email, authorization);

  if (item) {
    tiptapp.store.ad.set(item);
  }

  tiptapp.analytics.trackEvent('web_create_ad');

  if (!gExternalAd || !gExternalAd.id || !+gExternalAd.id) {
    sessionStorage.clear();

    tiptapp.saveUser({
      ...user,
      jwt: authorization,
    });
  }

  if (dynamicLink) {
    tiptapp.store.dynamicLink.set(dynamicLink);
  }

  if (item) {
    tiptapp.store.ad.set(item);
  }

  tiptapp.leanplum.setTrafficSource(String(user._id), item, gReferrerName);

  tiptapp.setScreen('final-state');

  if (!gExternalAd || !gExternalAd.id || !+gExternalAd.id) {
    tiptapp.store.state.remove();
  }
}

async function login(url, phone, code) {
  try {
    const body = {
      code,
      reference: String(gExternalAd.id),
      phone: tiptapp.format.phone(phone),
      referrerName: gReferrerName || 'blocket',
    };

    const { user, authorization } = await $.ajax({
      type: 'POST',
      url,
      data: JSON.stringify(body),
      contentType: 'application/json',
    });

    tiptapp.saveUser({
      ...user,
      jwt: authorization,
    });

    return { user, authorization };
  } catch (error) {
    console.log(error);
    const response = error.responseJSON || {};
    throw { type: 'login', message: response.message };
  }
}

async function publishAd(ad, images, user, email, auth) {
  let referrerName;

  try {
    referrerName = gReferrerName || 'blocket';
  } catch (err) {
    referrerName = 'blocket';
  }

  const data = { ad, images, sessionToken: user.pass, userId: user._id, referrerName };
  if (email) {
    data.email = email;
  }

  data.ownerFee = tiptapp.blocket.getOwnerFee(referrerName, gMarket.countryCode);

  // if (!_.has(data, 'ad.from.loc')) {
  //   throw { type: 'ad', message: 'Your address is invalid, please try again!' }
  // }

  // if (!_.has(data, 'ad.to.loc')) {
  //   throw { type: 'ad', message: 'The seller\'s address is invalid, please try again!' }
  // }
  if (!_.get(data, 'ad.from.helpToCarry', false)) {
    data.ad.from.helpToCarry = true;
  }
  if (!_.has(data, 'ad.to.helpToCarry', false)) {
    data.ad.to.helpToCarry = true;
  }

  try {
    const { dynamicLink, item } = await $.ajax({
      url: '/api/publish',
      type: 'POST',
      headers: { authorization: auth, ...tiptapp.main.getApiHeaders() },
      data: JSON.stringify(data),
      contentType: 'application/json',
    });
    return { dynamicLink, item };
  } catch (error) {
    const response = error.responseJSON || {};
    throw { type: 'ad', message: response.message };
  }
}

function getImages() {
  const imageElements = [].slice.call(document.getElementsByClassName('image-input'));

  let uploadedImages = imageElements
    .map(elm => (elm.id ? `adImage${elm.id.replace('image-input-', '')}` : ''))
    .map(tiptapp.store.getItem)
    .reduce((urls, url) => {
      url && urls.push(url);
      return urls;
    }, []);

  return uploadedImages.concat(gExternalAd.images || []);
}

// request login code
tiptapp.getLoginCode = (url, phone, grecaptchaId) => {
  const body = {
    phone: tiptapp.format.phone(phone),
    locale: gMarket.countryCode,
    country: gMarket.countryCode,
    captchaToken: grecaptchaId ? grecaptcha.getResponse(grecaptchaId) : grecaptcha.getResponse(),
    tempUserId: tiptapp.tempUserId,
  };

  let requestCode = $.ajax({
    url: url,
    type: 'POST',
    data: JSON.stringify(body),
    contentType: 'application/json',
  });

  // sending code successful
  requestCode.done(function(data) {
    if (data.phone) {
      tiptapp.store.ad.updatePhoneNumber('to', data.phone);
    }
    tiptapp.setScreen('login-state');
  });

  // sending code failed
  requestCode.fail(function(data) {
    if (!data || !data.responseText) {
      data = {
        responseText: 'Unknown error',
        status: 418,
        statusText: 'I’m a teapot',
      };
    }
    console.error(`Request Code Error ${data.status} ${data.statusText} – ${data.responseText}`);
    const { message } = data.responseText;
    tiptapp.blocket.toggleErrorModal(true, message);
  });
};

function isObject(value) {
  return typeof value === 'object' && value !== null;
}

// Local storage polyfill, from: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Local_storage
setupWebStorage('localStorage');
setupWebStorage('sessionStorage');
function setupWebStorage(name) {
  if (window[name]) {
    return;
  }

  window[name] = {
    getItem: function(sKey) {
      if (!sKey || !this.hasOwnProperty(sKey)) {
        return null;
      }
      return unescape(
        document.cookie.replace(
          new RegExp('(?:^|.*;\\s*)' + escape(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*'),
          '$1'
        )
      );
    },
    key: function(nKeyId) {
      return unescape(document.cookie.replace(/\s*\=(?:.(?!;))*$/, '').split(/\s*\=(?:[^;](?!;))*[^;]?;\s*/)[nKeyId]);
    },
    setItem: function(sKey, sValue) {
      if (!sKey) {
        return;
      }
      document.cookie = escape(sKey) + '=' + escape(sValue) + '; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/';
      this.length = document.cookie.match(/\=/g).length;
    },
    length: 0,
    removeItem: function(sKey) {
      if (!sKey || !this.hasOwnProperty(sKey)) {
        return;
      }
      document.cookie = escape(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      this.length--;
    },
    hasOwnProperty: function(sKey) {
      return new RegExp('(?:^|;\\s*)' + escape(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=').test(document.cookie);
    },
  };
  window[name].length = (document.cookie.match(/\=/g) || window[name]).length;
}
