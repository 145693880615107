/**
 * Getters for the form.
 */

window.tiptapp = window.tiptapp || {};

tiptapp.form = (function init() {
  const publicInterface = {
    fromAddress: {
      name: () => $('input[name=fromAddress]').val(),
      value: () => tiptapp.store.address.get('fromAddress'),
    },
    fromOwnerAssistanceAvailableCarry: {
      value: () => $('input[name=fromOwnerAssistanceAvailableCarry]').prop('checked'),
    },
    fromElevator: {
      value: () => $('input[name=fromElevator]').prop('checked'),
    },
    fromFloor: {
      value: () => $('input[name=fromFloor]').val(),
    },
    priceGroup: {
      value: () => $('input[name=priceGroup]:checked').val(),
    },
    timeSlots: {
      exists: () => $('input[name^="timeSlot"]').length > 0,
      /**
       * Clear all currently selected time-slots.
       */
      clearSelected: () => {
        $('input[name^="timeSlot"]').each(function onEach() {
          // Don't use arrow function here, it affects `this`.
          if ($(this).prop('checked')) {
            tiptapp.store.timeSlots.remove($(this).prop('name'));
          }
        });
      },

      /**
       * A `RegExp` object that can be used when testing for time-slot elements.
       */
      elementNameRegex: /^timeSlot/,

      /**
       * Extracts and returns the time-slots that the user has selected.
       *
       * @returns {array} Time-slots currently slected by the user, or an empty array.
       */
      getSelected: ({ twoHourSlots } = { twoHourSlots: false }) => {
        const selectedSlots = [];

        $('input[name^="timeSlot"]').each(function onEach() {
          // Don't use arrow function here, it affects `this`.
          if ($(this).prop('checked')) {
            const from = parseInt($(this).prop('value'), 10);
            selectedSlots.push({ f: from, t: from + (twoHourSlots ? 2 * 3600000 : 3600000) });
          }
        });

        return selectedSlots;
      },
    },
    toAddress: {
      name: () => $('input[name=toAddress]').val(),
      value: () => tiptapp.store.address.get('toAddress'),
    },
    toOwnerAssistanceAvailableCarry: {
      value: () => $('input[name=toOwnerAssistanceAvailableCarry]').prop('checked'),
    },
    toElevator: {
      value: () => $('input[name=toElevator]').prop('checked'),
    },
    toFloor: {
      value: () => $('input[name=toFloor]').val(),
    },
    items: {
      value: () => $('input[name=items]').val(),
    },
    articles: {
      value: () => $('input[name=articles]').val(),
    },
  };

  return publicInterface;
})();
