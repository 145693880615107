/**
 * Startup routines.
 */
window.tiptapp = window.tiptapp || {};

tiptapp.main = (function init() {
  const publicInterface = {
    handleInputUpdateEvent,
    reloadFormUsingStore,
    openDialog,
    renderTotalPrice,
    getApiHeaders,
    updateRecommendedPrice,
    closeSplash,
  };

  let highlightAnimTimer;
  let highlightAnimRunning = false;
  let campaignValue = 0;
  let campaignCurrency = '';
  let creditsValue = 0;
  let creditsCurrency = '';

  tiptapp.leanplum.setup(() => {
    const recommendedPrice = tiptapp[`recommendedPrice${window.gMarket.countryCode}`] || tiptapp.recommendedPrice;
    const lpKey = `recommendedPrice${window.gVariant === 'coop' ? 'Coop' : window.gMarket.countryCode}`;
    recommendedPrice.setRecommendedPrice(tiptapp.leanplum.getVars(lpKey));
    updateRecommendedPrice('', true);
  });

  window.onCaptchaLoad = () => {
    tiptapp.grecaptchaId = window.grecaptcha.render('g-recaptcha-main', {
      sitekey: tiptapp.captchaPublicKey,
    });
    tiptapp.grecaptchaResendId = window.grecaptcha.render('g-recaptcha-resend', {
      sitekey: tiptapp.captchaPublicKey,
    });
  };

  $(document).ready(function onReady() {
    const state = tiptapp.store.state.get();

    if (state) {
      tiptapp.setScreen(state);
    } else {
      tiptapp.setScreen('begin-state');
    }

    // Show Olx splash screen once per session
    const splashDisplayed = tiptapp.store.getLocalItem('splashDisplayed');
    if (window.gVariant === 'olx' && (!splashDisplayed || splashDisplayed <= Date.now()) && $('#olxSplash').length) {
      $('#olxSplashDim, #olxSplash').show();

      // eslint-disable-next-line no-new
      new window.Swiper('.swiper-container', {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        parallax: true,
        speed: 600,
      });
      const exDate = new Date();
      exDate.setDate(exDate.getDate() + window.gMarket.splashExpirationDays);
      tiptapp.store.setLocalItem('splashDisplayed', exDate.valueOf());
      $('body').addClass('noscroll');
    } else {
      $('#olxSplashDim, #olxSplash').hide();
      $('body').removeClass('noscroll');
    }

    // Show Coop splash screen once per session
    if (window.gVariant === 'coop' && (!splashDisplayed || splashDisplayed <= Date.now()) && $('#coopSplash').length) {
      $('#coopSplashDim, #coopSplash').show();
      $('body').addClass('noscroll');
      const exDate = new Date();
      exDate.setDate(exDate.getDate() + window.gMarket.splashExpirationDays);
      tiptapp.store.setLocalItem('splashDisplayed', exDate.valueOf());
    } else {
      $('#coopSplashDim, #coopSplash').hide();
      $('body').removeClass('noscroll');
    }

    // Pre-set 'extra info' to be opened instead of closed on OLX landing page
    if (window.gVariant === 'olx') {
      $('.extra-info div img:first-child').css('display', 'none');
      $('.extra-info div img:nth-child(2)').css('display', 'block');
    }

    if (window.gExternalAd.images) {
      // Convert path to URL, for upload.
      window.gExternalAd.images = window.gExternalAd.images.map(image => {
        if (/^http(s)?:\/\//.test(image)) {
          return image;
        }

        return `${window.location.origin || ''}${image}`;
      });
    }

    window.i18next
      .use(window.i18nextHttpBackend)
      .init({
        lng: window.i18nlocale,
        supportedLngs: ['en', 'en-SE', 'en-DE', 'en-PT', 'sv', 'sv-SE', 'sv-FI', 'pt', 'pt-PT', 'pt-BR', 'de', 'de-DE'],
        nonExplicitSupportedLngs: false,
        load: 'languageOnly',
        preload: ['en', 'sv', 'pt', 'de'],
        ns: ['common', 'store', 'tapaway', 'fb', 'move', 'coop', 'klint', 'applanga', 'budi'],
        fallbackLng: 'en',
        defaultNS: window.i18nnamespace,
        fallbackNS: 'common',
        backend: {
          loadPath: '/i18n/{{lng}}/{{ns}}.json',
        },
        missingInterpolationHandler: () => '',
      })
      .then(() => {
        tiptapp.blocket.init({
          tsConfig: {
            openDays: { klint: [1, 2, 3, 4, 5] }[window.gVariant] || [],
            startHr: { klint: 16 }[window.gVariant] || 8,
            endHr: { klint: 17, coop: 22 }[window.gVariant] || 24,
            endHrPerDay: { klint: { 5: 17 } }[window.gVariant] || {}, // Day index 0 = Sunday (5 = Friday)
            slotLengthHr: { klint: 1, coop: 2 }[window.gVariant] || 1,
            minSlotFracRemaining: { klint: 5.0, coop: 0.75 }[window.gVariant] || 0.5,
          },
        });

        tiptapp.wasteCategories.renderWasteCategories();
        tiptapp.wasteCategories.renderWasteCategoryRules();

        if (window.gVariant === 'coop') {
          tiptapp.coop.init();
        }

        if (window.gVariant === 'budi') {
          addressUpdateStoreFromHtmlDataAttribute('from');
        }

        reloadFormUsingStore();

        // NOTE: The `addressRestore()` functions must be called after `reloadFormUsingStore()`.
        addressRestore('from');
        addressRestore('to');

        const timeSlotDay = tiptapp.store.timeSlotDay.get();
        if (timeSlotDay) {
          $(`#${timeSlotDay}`).click();
        }

        const title = $('#ad-title').val();
        if (title && title !== (window.gExternalAd.title || '').substring(0, 25)) {
          $('#ad-preview-title').text(title);
          $('#title-and-images-edit-form').show();
        } else if (title) {
          $('#ad-preview-title').text(title);
        } else {
          $('#ad-title').val(window.gExternalAd.title);
        }

        // Show time-slots from that were last visible
        if (timeSlotDay && timeSlotDay === 'tomorrow') {
          $('#slot-container-today').hide();
          $('#slot-container-tomorrow').show();
        } else {
          $('#slot-container-tomorrow').hide();
          $('#slot-container-today').show();
        }

        tiptapp.imageupload.loadUploadedImages(10);

        // (tiptapp.store.getItem('removedExtAdImgs') || [])
        //   .map(url => $(`.image-input[id][value='${url}']`))
        //   .filter($elm => $elm.length > 0)
        //   .forEach($elm => tiptapp.imageupload.removeImage($elm.attr('id').replace('image-input-', '')));

        const previousError = tiptapp.store.error.get();
        if (previousError) {
          // Do the validation after the recommended price has been updated.
          setTimeout(tiptapp.validation.validateOnPublish, 200);
        }

        // Making sure the tracking is done after the updateRecommendedPrice recommendedPrice.update() call is done.
        setTimeout(() => {
          tiptapp.analytics.getUserId().then(() => {
            tiptapp.store.setItem('externalAd', window.gExternalAd);
            tiptapp.analytics.trackEvent('web_session_start');
          });
        }, 4000);

        if ($('#campaign-code').val()) {
          verifyCampaignCode();
        }

        // add 'click' event listener to all dynamically created time-slot event listener
        $('div[id^="slot-container-"]').on('click', $('.time-slot'), () => {
          tiptapp.validation.validateSlots();
        });

        if (window.gReferrerName) {
          const ownerFee = tiptapp.blocket.getOwnerFee(window.gReferrerName, window.gMarket.countryCode);
          if (ownerFee > 0) {
            $('#tiptapp-owner-fee')
              .removeClass('hide')
              .text(window.i18next.t('tiptapp_admin_fee_text', { value: ownerFee, currency: window.gMarket.paymentCurrencySymbol }));
          }
        }
      });

    $('input[name$=Address]').change(event => {
      const address = event.tiptappAddress;

      if (typeof address === 'undefined') {
        return;
      }

      tiptapp.store.address.set(event.target.name, address);
      tiptapp.store.input.remove(event.target.name);
      updateRecommendedPrice(event.target.name);
    });

    // Focuses next input field upon pressing enter/ok.
    $('#ad-create-form').on('keydown', 'input', handleInputEnterKeyEvent);

    $('input:not([type=checkbox],[type=radio])').on('input', handleInputUpdateEvent);
    $('input[type=checkbox],input[type=radio],input[type=text],select').on('change', handleInputUpdateEvent);

    tiptapp.imageupload.setImageEvents($('.image-input'));

    tiptapp.imageupload.onImagesChange(imageUrls => {
      $('#ad-preview-img').css('background-image', `url('${imageUrls[0] || ''}')`);
      if (imageUrls.length) {
        $('#ad-preview-img-num')
          .show()
          .text(imageUrls.length);
      } else {
        $('#ad-preview-img-num').hide();
      }
      tiptapp.validation.validateImages('image-input');
    });

    $('#edit-title-and-images-button').click(() => {
      $('#title-and-images-edit-form').slideToggle('medium');
    });

    $('#ad-title').on('input change', function onChange() {
      $('#ad-preview-title').html($(this).val() || (window.gExternalAd.title || '&nbsp;'));
    });

    $('#campaign-code').on('input change', function onChange() {
      $('#campaign-code-error').addClass('hide');
      $('#submit-campaign-code').removeClass('confirmed');
      $('#campaign-code-info').addClass('hide');
      $('#ad-price-with-credits').addClass('hide');
    });

    $('#submit-campaign-code').click(verifyCampaignCode);
  });

  function verifyCampaignCode() {
    $('#ad-price-with-credits').addClass('hide');
    $('#campaign-code-error').addClass('hide');
    $('#campaign-code-server-error').addClass('hide');

    const code = $('#campaign-code').val();
    const { _id: uid, jwt: auth } = tiptapp.store.user.get() || {};
    $.ajax({
      method: 'GET',
      url: `/api/campaign?code=${code}&uid=${uid}&auth=${auth}`,
      headers: getApiHeaders(),
    })
      .done(response => {
        if (response) {
          campaignValue = response.value;
          campaignCurrency = response.currencySign;
          creditsValue = response.credits;
          creditsCurrency = response.creditsCurrency; //eslint-disable-line
          $('#submit-campaign-code').addClass('confirmed');
          $('#ad-price-with-credits').removeClass('hide');
          renderTotalPrice();
          if (response.type === 6) {
            $('#campaign-code-info').removeClass('hide');
          }
        } else {
          $('#campaign-code-error').removeClass('hide');
        }
      })
      .fail(() => {
        $('#campaign-code-server-error').removeClass('hide');
      });
  }

  function renderTotalPrice() {
    const price = parseInt($('#ad-price').val(), 10);
    if (!Number.isNaN(price) && Number.isInteger(window.gExternalAd.PriceSumIncludingVAT)) {
      const goodsPriceHundredths = window.gExternalAd.PriceSumIncludingVAT;
      const goodsPriceWhole = Math.floor(goodsPriceHundredths / 100);
      const goodsPriceRemainder = goodsPriceHundredths % 100;
      const totalPrice = `${price + goodsPriceWhole}:${goodsPriceRemainder.toString().padStart(2, '0')}`;
      $('#ad-price-with-goods').text(`${totalPrice} kr`);
    }

    if (!Number.isNaN(price) && Number.isInteger(campaignValue) && campaignCurrency) {
      const totalTiptappPrice = price - campaignValue - (creditsValue || 0);
      if (totalTiptappPrice > 0) {
        let keyName = 'x_credits_added_you_pay_y';
        if (creditsValue && creditsCurrency) keyName = 'x_credits_added_and_previous_credits_you_pay_y';
        $('#ad-price-with-credits').text(
          window.i18next.t(keyName, { value: campaignValue, total: totalTiptappPrice, currency: campaignCurrency, creditsValue, creditsCurrency })
        );
      } else if (totalTiptappPrice === 0) {
        let keyName = 'x_credits_added_you_pay_nothing';
        if (creditsValue && creditsCurrency) keyName = 'x_credits_added_and_previous_credits_you_pay_nothing';
        $('#ad-price-with-credits').text(
          window.i18next.t(keyName, { value: campaignValue, currency: campaignCurrency, creditsValue, creditsCurrency })
        );
      } else if (totalTiptappPrice < 0) {
        let keyName = 'x_credits_added_you_pay_nothing_with_residual';
        if (creditsValue && creditsCurrency) keyName = 'x_credits_added_and_previous_credits_you_pay_nothing_with_residual';
        $('#ad-price-with-credits').text(
          window.i18next.t(keyName, { value: campaignValue, currency: campaignCurrency, rest: -1 * totalTiptappPrice, creditsValue, creditsCurrency })
        );
      }
    }
  }

  /**
   * React on input element input/change events.
   *
   * @param {object} event The HTML event object.
   */
  function handleInputUpdateEvent(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    // FIXME: Should be refactored so that call-site doesn't set the key.
    tiptapp.store.input.set(`${event.target.type}|${event.target.name}`, value);
    updateRecommendedPrice(event.target.name);

    if (tiptapp.form.timeSlots.elementNameRegex.test(event.target.name)) {
      updateNumberOfSelectedTimeSlots();
    }
  }

  /**
   * Focus the next input field on enter key presses within input fields.
   *
   * @param {object} event The HTML event object.
   */
  function handleInputEnterKeyEvent(event) {
    if (event.which === 13) {
      event.preventDefault();

      $.each($('input'), (index, input) => {
        if (input === event.currentTarget) {
          $('input')[index + 1].focus();
        }
      });
    }
  }

  /**
   * Reload all user inserted values with values found in the store.
   *
   * @param {RegExp} elementNameFilterRegex Optional `RegExp` object that will filter which elements that should be restored.
   */
  function reloadFormUsingStore(elementNameFilterRegex) {
    tiptapp.store
      .getFormItems(elementNameFilterRegex)
      .filter(ith => ith.value != null && ith.value !== '')
      .forEach(ith => {
        switch (ith.type) {
          case 'checkbox':
            $(`input[type=checkbox][name=${ith.name}]`).prop('checked', ith.value);
            break;

          case 'radio':
            $(`input[type=radio][name=${ith.name}][value=${ith.value}]`).prop('checked', true);
            break;

          case 'select-one':
            $(`select[name=${ith.name}]`).val(ith.value);
            break;

          default:
            $(`input[name=${ith.name}]`).val(ith.value);
        }

        $(`input[name=${ith.name}]`).attr('touched', 'true');
      });

    // NOTE
    // A bit of a hack here, calling an external global function like this, it will have
    // to suffice until we have refactored the very first MVP code. I would rather it is
    // hooked up elsewhere to a DOM event than being called explicitly like this.
    tiptapp.blocket.updateSelectedPriceBoxDescription();

    // Force update the recommended pricing.
    updateRecommendedPrice('', true);

    updateNumberOfSelectedTimeSlots();
  }

  /**
   * Restore the given address from web storage.
   *
   * @param {string} type Type of address `from` or `to`.
   */
  function addressRestore(type) {
    const address = tiptapp.store.address.get(`${type}Address`);
    if (address && address.name) {
      $(`input[name=${type}Address]`)
        .val(address.displayName || address.name)
        .attr('data-tiptappAddress', JSON.stringify(address));
    }
  }

  function addressUpdateStoreFromHtmlDataAttribute(type) {
    const address = $(`input[name='${type}Address']`).attr('data-tiptappAddress');
    if (!address) {
      return;
    }
    tiptapp.store.address.set(`${type}Address`, JSON.parse(address));
  }

  /**
   * Update the help text describing the number of currently selected time-slots.
   */
  function updateNumberOfSelectedTimeSlots() {
    const selectedSlots = tiptapp.form.timeSlots.getSelected();
    if (selectedSlots.length > 0) {
      highlightSlotSelectionText();
    }
    const selectedMessage =
      selectedSlots.length > 1 ? `${selectedSlots.length} ${window.i18next.t('timeslots_chosen')}` : window.i18next.t('one_timeslot_chosen');
    $('#min-time-slot-text').text(selectedSlots.length > 0 ? `(${selectedMessage})` : window.i18next.t('choose_one_or_several_timeslots'));
  }

  function highlightSlotSelectionText() {
    $('#min-time-slot-text')
      .stop()
      .animate({ color: '#2a91f5' }, 0)
      .animate({ color: '#000' }, 1000);
  }

  /**
   * Update the recommended pricing.
   *
   * @param {string} elementName Name of the element that fired an event.
   * @param {boolean} force Set to `true` in order to force an update.
   */
  function updateRecommendedPrice(elementName, force) {
    const map = { fromAddress: 1, fromElevator: 1, fromFloor: 1, priceGroup: 1, toAddress: 1, toElevator: 1, toFloor: 1, items: 1, articles: 1 };
    if (force || map[elementName] || /^timeSlot/.test(elementName)) {
      setTimeout(() => {
        // HACK: the event chain has not finished updating session storage when this routine
        // is fired after input change, so the whole update should be refactored.
        const recommendedPrice = tiptapp[`recommendedPrice${window.gMarket.countryCode}`] || tiptapp.recommendedPrice;
        const updated = recommendedPrice.update();
        renderTotalPrice();
        if (updated && !force) {
          highlightFloatingPrice();
        }
      }, 100);
    }
  }

  function highlightFloatingPrice() {
    if (!highlightAnimRunning) {
      $('.recommended-price')
        .stop()
        .animate({ color: '#08d88c' }, 0)
        .animate({ color: '#383838' }, 1000);
    }

    highlightAnimRunning = true;

    clearTimeout(highlightAnimTimer);

    highlightAnimTimer = setTimeout(() => {
      highlightAnimRunning = false;
    }, 500);
  }

  function openDialog(url, cssClass, modalContent) {
    window.uglipop({
      class: cssClass,
      source: 'div',
      content: modalContent,
    });
    $('#uglipop_popbox .dialogInner').load(url);
    $('#uglipop_popbox .dialogInner').scrollTop(0);
    return false;
  }

  function closeSplash() {
    $('#coopSplashDim, #coopSplash').hide();
    $('#olxSplashDim, #olxSplash').hide();
    $('body').removeClass('noscroll');
  }

  function getApiHeaders() {
    return {
      'Accept-Language': window.i18next.language,
    };
  }

  return publicInterface;
})();
